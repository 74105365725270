
import LocationIcon from '../../images/overview/btn--location.png';
import EducationIcon from '../../images/overview/btn--education-research-institutions.png';
import HumanIcon from '../../images/overview/btn--human-capital.png';
import DemographyIcon from '../../images/overview/btn--demography.png';
import InfrastructureIcon from '../../images/overview/btn--infrastructure-access.png';
import EconomicIcon from '../../images/overview/btn--economic-fundamentals.png';
import SizeIcon from '../../images/overview/btn--size-scale.png';


export const data = [
    { 
        title: 'Location',
        titleXPos: 74,
        titleYPos: 400,
        descriptionXPos: 100,
        descriptionYPos: 275,
        description: 'Optimal position between significant population centres, in close proximity to highly productive primary production lands and well supported by critical economic infrastructure.',
        btnXPos: 220,
        btnYPos: 454,
        btnIcon: LocationIcon
    },
    { 
        title: 'Education & research\ninstitutions',
        titleXPos: 290,
        titleYPos: 790,
        descriptionXPos: 310,
        descriptionYPos: 884,
        description: 'Education and research institutions, a regional university, TAFE and research faculties in the region provide opportunities for linkages with sectors such as agribusiness and advanced manufacturing.',
        btnXPos: 444,
        btnYPos: 680,
        btnIcon: EducationIcon
    },
    { 
        title: 'Human\ncapital',
        titleXPos: 520,
        titleYPos: 350,
        descriptionXPos: 545,
        descriptionYPos: 250,
        description: 'The regional population boasts skilled labour and high levels of educational attainment, with a large and growing working age population.',
        btnXPos: 670,
        btnYPos: 454,
        btnIcon: HumanIcon
    },
    { 
        title: 'Demography',
        titleXPos: 745,
        titleYPos: 790,
        descriptionXPos: 770,
        descriptionYPos: 846,
        description: 'A large and rapidly growing regional population is forecast to significantly outpace the average rate for Victoria over the longer term and to underpin regional economic growth.',
        btnXPos: 892,
        btnYPos: 680,
        btnIcon: DemographyIcon
    },
    { 
        title: 'Infrastructure\naccess',
        titleXPos: 975,
        titleYPos: 350,
        descriptionXPos: 995,
        descriptionYPos: 250,
        description: 'Direct access to critical supporting infrastructure including air, port, road and rail to support a range of industrial and commercial uses.',
        btnXPos: 1122,
        btnYPos: 454,
        btnIcon: InfrastructureIcon
    },
    { 
        title: 'Economic\nfundamentals',
        titleXPos: 1205,
        titleYPos: 790,
        descriptionXPos: 1232,
        descriptionYPos: 884,
        description: 'A large and growing regional economy, with an extensive infrastructure investment pipeline, and growing nonresidential building investment.',
        btnXPos: 1348,
        btnYPos: 680,
        btnIcon: EconomicIcon
    },
    { 
        title: 'Size and\nscale',
        titleXPos: 1422,
        titleYPos: 350,
        descriptionXPos: 1445,
        descriptionYPos: 220,
        description: 'An unprecedented landholding in the Geelong industrial market, enabling consolidation of land uses and genuine long-term, freehold investment opportunities for owner-occupiers.',
        btnXPos: 1574,
        btnYPos: 454,
        btnIcon: SizeIcon
    }
];