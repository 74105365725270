
import React, { useState } from 'react';
import { data } from './dataOverview';
import ViewOverviewBkg from './ViewOverviewBkg';
import ViewOverviewTitle from './ViewOverviewTitle';
import ViewOverviewOverlay from './ViewOverviewOverlay';
import ButtonOverviewItem from './ButtonOverviewItem';
import styles from './stylesOverview.module.scss';


export default function ViewOverview () {

    const [ currItem, setCurrItem ] = useState(null);
    const onItemSected = (item) => {
        const newItem = item;
        setCurrItem(newItem);
    }

    const getTitles = () => {
        const titles = data.map( item => 
            <ViewOverviewTitle key={ item.title } item={ item } currItem={ currItem }  />
        );
        return titles;
    }

    const getOverlays = () => {
        const overlays = data.map( item => 
            <ViewOverviewOverlay key={ item.title } item={ item } currItem={ currItem }  />
        );
        return overlays;
    }

    const getButtons = () => {
        const buttons = data.map( item => 
            <li key={ item.title }>
                <ButtonOverviewItem key={ item.title } item={ item } currItem={ currItem } selectItem={ onItemSected } />
            </li>
        );
        return buttons;
    }

    return (
        <>
            <ViewOverviewBkg />
            <div className={ styles.frg }>
                { getTitles() }
                { getOverlays() }
            </div>
            <nav>
                <ul>
                    { getButtons() }
                </ul>
            </nav>
        </>
    );

}