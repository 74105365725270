import React from 'react'
import styles from './stylesOverview.module.scss';


export default function ButtonOverviewItem ( { item, currItem, selectItem } ) {
  
  return (
    <button 
      className={ styles.btn } 
      style={ { left: item.btnXPos, top: item.btnYPos } }
      onClick={ () => selectItem(item) } 
    >
      <img className={ styles.btnIcon } src={ item.btnIcon } width='98' height='98' alt={ item.title + ' icon' } />
    </button>
  );
}
