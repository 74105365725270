import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';


export default function ViewOverviewBkg () { 

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "overview/bkg-the-opportunity-overview-2.png"}) {
        childImageSharp {
          fixed(width: 1920, height: 1080, quality: 80) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }  
  `)

  return (
    <Img fixed={ data.file.childImageSharp.fixed } alt='Background' />
  );

}

