import React, { useRef, useEffect } from 'react';
import styles from './stylesOverview.module.scss';
import { utilAnimate } from '../../utils/utilAnimate';


export default function ViewOverviewOverlay ( { item, currItem } ) {

	const containerRef = useRef(null);
	useEffect(() => {
		if (currItem !== null) {
			item.title === currItem.title ? utilAnimate.fadeIn(containerRef.current) : utilAnimate.fadeOut(containerRef.current);
		}
  	});

	if (currItem === null) return (<></>);

  	return (
		<div ref={ containerRef } className={ styles.overlay } style={ { left: item.descriptionXPos, top: item.descriptionYPos } }>
			<p>{ item.description }</p>
		</div>
  	);

}
