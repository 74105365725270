import React from 'react';
import SEO from '../components/seo/SEO';
import ViewOverview from '../components/overview/ViewOverview';


export default function Overview () { 

  return (
    <>
      <SEO title='Overview' />
      <ViewOverview />
    </>
  );

}
