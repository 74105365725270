import React from 'react'
import styles from './stylesOverview.module.scss';


export default function ViewOverviewTitle ( { item, currItem } ) {
	  
	return (
		<h2 className={ styles.title } style={ { left: item.titleXPos, top: item.titleYPos } }>{ item.title }</h2>
  	);

}
